import { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';

const CustomLottie = ({ animData, width = '100%', height = 'auto', isStopped, isPaused = false }) => {
	const lottieRef = useRef();

	useEffect(() => {
		if (isStopped) {
			lottieRef.current?.stop();
		} else if (isPaused) {
			lottieRef.current?.pause();
		} else {
			lottieRef.current?.play();
		}
	}, [isStopped, isPaused]);

	return (
		<Lottie
			loop
			lottieRef={lottieRef}
			animationData={animData}
			style={{ width, height }}
		/>
	);
};

export default CustomLottie;
