import { memo as Memo, useCallback, useMemo, Fragment, useRef } from 'react';
import { TimelineMax, TimelineLite } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

//* Helpers
import isMobile from '@helpers/isMobile';
import stringToLetters from '@helpers/stringToLetters';

//* Components
import Text from '../Text';
import CustomLink from '@components/global/CustomLink';
import ArrowLeftBtn from '@components/global/SvgComponents/ArrowLeftBtn';
import ArrowBtnLink from '@components/global/SvgComponents/ArrowBtnLink';

//* Styles
import ButtonStyle from './style';

const Button = Memo(({ text, otherLink, scrollLink, teamFormBtn, children, className, link, active, turnAnimationParams, customClass, otherLinkProps, ...props }) => {
	//! Refs
	const ref = useRef();

	//! Animation
	const handleHover = useCallback((enter) => {
		if (!isMobile()) {
			let splitTxt = new SplitText('.load-more', { type: 'chars,words' });
			let tl = new TimelineMax({ repeat: 0 });

			if (enter) {
				tl.staggerTo(splitTxt.chars, 0.15, { fontWeight: 700 }, 0.02);
			} else {
				tl.staggerTo(splitTxt.chars, 0.15, { fontWeight: 300 }, 0.02);
			}
		}
	}, []);

	const tl = useMemo(() => new TimelineLite({ paused: true }), []);

	const turnAnimation = useCallback((enter) => {
		if (enter) {
			if (turnAnimationParams) {
				tl.to(ref.current, 0.2, { rotation: -18 }).play();
			} else {
				tl.to(ref.current, 0.2, { rotation: 15 }).play();
			}
		} else {
			tl.reverse();
		}
	}, []);

	const letterifyText = useMemo(() => stringToLetters(text), [text]);

	//! Button Type
	const btn = useMemo(() => {
		if (link) {
			return (
				<ButtonStyle className={`arrow-btn crCircle buttonCont`}>
					<span
						onMouseEnter={() => handleHover(true)}
						onMouseLeave={() => handleHover(false)}>
						<CustomLink
							url={link}
							className={`button ${props.type === 1 ? 'black' : 'white'} ${props.fontSize || 'h4'} font-poppins font-italic ${customClass}`}
							{...props}
							content={
								<Fragment>
									<ArrowBtnLink animate /> {letterifyText}
								</Fragment>
							}
						/>
					</span>
				</ButtonStyle>
			);
		} else if (otherLink) {
			return (
				<CustomLink
					url={props.as || typeof otherLink === 'object' ? otherLink.pathname : otherLink}
					className={`otherLinkWrap ${className || ''}`}
					{...otherLinkProps}>
					<div
						className={'otherLink'}
						ref={ref}
						onMouseEnter={() => turnAnimation(true)}
						onMouseLeave={() => turnAnimation(false)}>
						<Text className={`p-m font-anonymous font-bold font-italic`}>{text}</Text>
					</div>
				</CustomLink>
			);
		} else if (scrollLink) {
			return (
				<ButtonStyle
					className={`otherLinkWrap ${className || ''}`}
					onClick={props.onClick}>
					<div
						className={'otherLink'}
						ref={ref}
						onMouseEnter={() => turnAnimation(true)}
						onMouseLeave={() => turnAnimation(false)}>
						<Text className={`p-m font-anonymous font-bold font-italic`}>{text}</Text>
					</div>
				</ButtonStyle>
			);
		} else if (teamFormBtn) {
			return (
				<ButtonStyle className={`teemformBtn`}>
					<div onClick={props.onClick}>
						<ArrowLeftBtn animate />
					</div>
					<button
						className={`teemButtonForm p-m crCircle font-poppins font-medium`}
						onClick={props.onClick}
						{...props}>
						{text || children}
					</button>
				</ButtonStyle>
			);
		}

		return (
			<ButtonStyle className={'buttonFormCont'}>
				<button
					className={`buttonForm p-m font-poppins font-medium crCircle`}
					onClick={props.onClick}
					{...props}>
					{text || children}
				</button>
			</ButtonStyle>
		);
	}, [className, text, children, otherLink, link, customClass, props]);

	return btn;
});

export default Button;
