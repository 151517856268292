import { memo as Memo } from 'react';

//* Components
import CustomLottie from '../../CustomLottie';

//* JSONs
import animJSON from './animJSON.json';

const NotFoundAnimation = Memo(() => {
	return (
		<CustomLottie
			isStopped={false}
			animData={animJSON}
		/>
	);
});

export default NotFoundAnimation;
