'use client';

//* Components
import Text from '@components/global/Text';
import Button from '@components/global/Button';
import HTMLContainer from '@components/global/HTMLContainer';
import NotFoundAnimation from '@components/global/SvgComponents/NotFoundAnimation';

//* Styles
import NotFoundStyle from '@styles/pages/NotFoundStyle';

const Custom404 = () => {
    return (
        <NotFoundStyle className={'page error-page'}>
            <HTMLContainer className='error-cont'>
                <div className='error-inner-cont'>
                    <div className='part err-left'>
                        <Text
                            className='h6'
                            text='Oops, it seems you’re getting into a black hole. Or a black hole is getting you. Go home, be safe.'
                        />

                        <Button
                            link='/'
                            type={1}
                            text={'go home'}
                        />
                    </div>

                    <div className='part err-right'>
                        <NotFoundAnimation />
                    </div>
                </div>
            </HTMLContainer>
        </NotFoundStyle>
    );
}

export default Custom404;
