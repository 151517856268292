import styled from 'styled-components';

const NotFoundStyle = styled.main`
	&.error-page {
		display: flex;
		align-items: center;
		justify-content: center;

		.error-cont {
			--errColPaddingSize: var(--sp3x);

			.error-inner-cont {
				min-width: 100%;
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(-1 * var(--errColPaddingSize));

				.part {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 0 var(--errColPaddingSize);

					&.err-left {
						.arrow-btn {
							display: inline-flex;

							.word {
								text-align: left;

								.letter:nth-child(1) {
									box-shadow: none;
								}
							}
						}
					}
				}
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				.arrow-btn {
					margin-top: var(--sp15x);
				}
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				height: 100%;
				display: flex;
				align-items: center;

				.error-inner-cont {
					.part {
						width: 50%;
					}
				}
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
				--errColPaddingSize: var(--sp6x);
			}
		}
	}
`;

export default NotFoundStyle;
